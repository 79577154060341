import type {SiteLocaleConfig, SiteMap} from '~/interfaces/multisite';
import {toValue} from 'vue';

const siteMapToArray = (sitesMap: SiteMap): SiteMap => Object.entries(sitesMap).map(([key, value]) => ({...value, uri: key}));

export const useLanguages = () => {
    const {activeSite, sites} = useMultisite();

    const url = useRequestURL();

    const getSitesLanguages = (group: string, type: string): SiteLocaleConfig[] => {
        if (!sites || !group) {
            return [];
        }

        const groupedSites = siteMapToArray(sites).filter(site =>
            site.group === group &&
            site.type === type
        );

        const uniqueSitesByLanguage = new Map<string, SiteLocaleConfig>();

        groupedSites.forEach((site: any)  => {
            if (uniqueSitesByLanguage.has(site.language)) {
                return;
            }
            uniqueSitesByLanguage.set(site.language, site);
        });

        return Array.from(uniqueSitesByLanguage.values());
    };

    return computed(() => {
        if(!activeSite.value || !activeSite.value.group) {
            return [];
        }

        return getSitesLanguages(
            toValue(activeSite).group,
            toValue(activeSite).type
        ).map(site => ({
            title: site.language,
            rtl: site.rtl ?? undefined,
            uri: site?.uri,
            locale: site?.locale,
            isProduction: !['local', 'dev'].includes(toValue(activeSite).type),
            active: site?.uri === url?.hostname,
        }));
    });
};
