<template>
    <multiselect
        id="main-language-select"
        class="base-lang-switch"
        :value="language"
        :model-value="language"
        :name="'main-language-select'"
        track-by="uri"
        track-by-property="uri"
        label="title"
        :placeholder="language?.title ?? 'Language'"
        :options="languages"
        :searchable="false"
        :close-on-select="true"
        :show-labels="false"
        :allow-empty="true"
        :preserve-search="true"
        @update:model-value="goToLanguage($event)"
    >
        <template #singleLabel="props">
            <div class="base-lang-switch__button">
                {{ formatLocale(props.option.locale) }}
                <BaseIcon
                    icon="language"
                    class="base-lang-switch__icon"
                />
            </div>
        </template>
    </multiselect>
</template>

<script setup>
import Multiselect from 'vue-multiselect';

const languages = useLanguages();
const language = ref(null);

onMounted(() => {
    language.value = toValue(languages)?.find(lang => lang.active);
});

const goToLanguage = (selected) => {
    if(!selected?.uri) {
        return;
    }

    language.value = toValue(languages).find(lang => lang.uri === selected.uri);

    const targetUrl = !selected.uri.startsWith('http') ?
        `http://${selected.uri}${language.value?.isProduction ? '' : ':3000'}` :
        selected.uri;

    // Todo: Get translated URI of the current page for language-specific redirection
    // if(route.path !== '/') {
    //     targetUrl += route.path;
    // }
    navigateTo(targetUrl, { external: true });
};

const formatLocale = (locale) => {
    if (!locale) {
        return '';
    }

    return locale.split('-')[0]?.toUpperCase();
};
</script>
<style lang="less">
.base-lang-switch {
    &.multiselect {
        display: flex;
        align-items: center;
        outline: none;

        justify-content: flex-end;

        .uro-navigation:not(.uro-navigation--center-logo) & {
            justify-content: flex-start;

            @media @q-lg-min {
                justify-content: flex-end;
            }
        }
    }

    .multiselect__single {
        margin-bottom: 0;
    }

    .multiselect__select {
        display: none;
    }

    .multiselect__tags {
        min-height: unset;
        cursor: pointer;
        display: inline-block;
        padding: 0;
    }

    .multiselect__tags,
    .multiselect__input,
    .multiselect__single {
        background: none;
        border: 0;
    }

    .multiselect__input, .multiselect__single {
        font-weight: 700;
    }

    .multiselect__content-wrapper {
        position: fixed;
        top: 5rem;
        padding: var(--grid-side-gap-sm);
        left: var(--grid-side-gap-sm);
        right: var(--grid-side-gap-sm);
        width: calc(100% - (var(--grid-side-gap-sm) * 2));
        border: 0;
        border-radius: 1.875rem;
        background: #fff;
        box-shadow: 0 0 6px 0 #00000029;

        max-height: calc(100svh - 6rem);
        overflow: auto;

        @media @q-lg-min {
            width: 22rem;
            left: auto;

            html[dir='rtl'] & {
                left: var(--grid-side-gap-sm);
                right: auto;
            }
        }
    }

    .multiselect__content {
        list-style: none;
        display: flex !important; /* stylelint-disable-line declaration-no-important */
        flex-direction: column;
        gap: 1.25rem;
        margin: 0;
        padding: 0;
    }

    .multiselect__element {
        font-size: .9375rem;
        padding-left: 0;
        margin-bottom: 0;
        cursor: pointer;

        @media @q-lg-min {
            font-size: 1.125rem;
        }

        &:before {
            display: none;
        }

        &:hover {
            color: var(--color-primary-300);
        }

        &[aria-selected='true'] {
            color: var(--color-alt-dark);
            font-family: Spartan, sans-serif;
            letter-spacing: -1px;
            order: -1;
        }
    }

    .multiselect__option {
        padding: 0;
        background: none;
        color: currentColor;
        min-height: unset;
    }
}

.base-lang-switch__button {
    display: flex;
    align-items: center;
    line-height: 1rem;
    gap: .2rem;
    font-size: .75rem;
    padding: .5rem .75rem;
    background: transparent;
    border-radius: .25rem;

    @media @q-lg-min {
        font-size: 1.125rem;
    }

    .multiselect--active & {
        background: var(--color-alt-light);
        color: var(--color-alt-dark);
    }
}

.base-lang-switch__icon {
    margin-top: -.125rem;

    svg {
        height: 1rem;
        width: 1rem;


        @media @q-lg-min {
            height: 1.5rem;
            width: 1.5rem;
        }
    }

    path {
        fill: var(--color-alt-dark);
        stroke: none;
    }
}
</style>
